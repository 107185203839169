import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--bank-details"
export default class extends Controller {
  connect() {
    bankDetailValidator()
    // applyDatePickerNextPaymentDate()
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });

    timeout_session('#{encoded_url}')
    $('.bank_pupop_btn').trigger("click");
    $("#bank_detail_v3").validate({
      rules: {
        "applicant[account_card_number]": {
          required: true,
          digits: true,
          accountcardregex: true,
          minlength: 16,
          maxlength: 16
          },
        "applicant[card_exp_month]": {
          required: true,
          minlength: 1,
          maxlength: 2,
          min: 1,
          max: 12
        },
        "applicant[card_exp_year]": {
          required: true,
          digits: true
        },
        "applicant[card_cvv]": {
          required: true,
          digits: true,
          minlength: 3,
          maxlength: 4
        },
        "applicant[bank_aba]": {
          required: true,
          digits: true,
          minlength: 9,
          maxlength: 9
        },
        "applicant[bank_account_number]": {
          required: true,
          digits: true,
          minlength: 5,
          maxlength: 25
        },
        "applicant[next_payment_date]": {
          required: true,
          dateregex: true
        },
        "applicant[income_pay_last]": {
          required: true,
          dateregex: true
        },
        'applicant[payment_frequency]': {
          required: true
        }
      },
      messages: {
        "applicant[account_card_number]": {
          required: "Please enter debit card number.",
          accountcardregex: "Please enter a valid debit card number.",
          digits: "Sorry, only numbers are allowed.",
          minlength: 'Sorry, debit card must be 16 digits.',
          maxlength: 'Sorry, debit card must be 16 digits.'
        },
        "applicant[card_exp_month]": {
          required: 'Please select card expiry month.',
          minlength: '',
          maxlength: '',
          min: 'Sorry, Card expiry month must be between 1 and 12.',
          max: 'Sorry, Card expiry month must be between 1 and 12.'
        },
        "applicant[card_exp_year]": {
          required: 'Please select card expiry year.',
          digits: 'Sorry, only numbers are allowed.'
        },
        "applicant[card_cvv]": {
          required: 'Please enter vaild CVV number.',
          digits: 'Sorry, only numbers are allowed.',
          maxlength: "Sorry, CVV number must be 3 or 4 digits.",
          minlength: "Sorry, CVV number must be 3 or 4 digits."
        },
        "applicant[bank_aba]": {
          required: "Please enter your bank's ABA (routing) number.",
          digits: 'Sorry, only numbers are allowed.',
          maxlength: "Sorry, the ABA number must be 9 characters long.",
          minlength: "Sorry, the ABA number must be 9 characters long."
        },
        "applicant[bank_account_number]": {
          required: 'Please enter bank account number.',
          digits: 'Sorry, only numbers are allowed.',
          maxlength: "Sorry, account number must be between 5 and 25 digits.",
          minlength: "Sorry, account number must be between 5 and 25 digits."
        },
        "applicant[next_payment_date]": {
          required: "Please select next payment date.",
          dateregex: 'Next Payment date must be in mm/dd/yyyy format.'
        },
        "applicant[income_pay_last]": {
          required: "Please select last payment date.",
          dateregex: "Last Payment date must be in mm/dd/yyyy format."
        },
        'applicant[payment_frequency]': {
          required: 'Please select your pay frequency.'
        }
      }
    });

    submitBtnEnableDisable();

    $('input').keyup(function(){
      submitBtnEnableDisable();
    })

    $('select').change(function(){
      submitBtnEnableDisable();
    })

    $('#bank_detail_v3').on('mouseover', function function_name(argument) {
      submitBtnEnableDisable();
    });

    $('#applicant_payment_frequency').change(function() {
      var today = new Date();
      var maxDate = new Date(today);
      if($(this).val() == 'twice_per_month'){
        $('.income_pay_last').show()
        $('.income_pay_last input').val('')
        $('.income_pay_last input').prop('required', true)
        
        maxDate.setDate(maxDate.getDate() + 28);
        $('#applicant_income_pay_last').datepicker({
          startDate: '-20d',
          endDate: today,
          autoclose: true,
          format: 'mm/dd/yyyy',
          todayHighlight: true,
        }).on('changeDate', function(e) {
          var minDate = new Date(e.date.valueOf())
          $('#applicant_next_payment_date').datepicker('setStartDate', minDate)
          $('#applicant_next_payment_date').datepicker('setEndDate', maxDate)
        });
        
        $('#applicant_next_payment_date').datepicker('destroy');
        $('#applicant_next_payment_date').datepicker({
          startDate: today,
          endDate: maxDate,
          autoclose: true,
          format: 'mm/dd/yyyy',
          todayHighlight: true
        });

        $('#applicant_next_payment_date').rules('add', {
          validWithin28Days: true,
          validDateRange: true,
          messages: {
            validDateRange: "Income Pay Next must be between 10 to 16 days after Income Pay Last.",
            validWithin28Days: 'Both dates must be within the next 28 days.'
          }
        });

        $('#applicant_income_pay_last').rules('add', {
          validWithin28Days: true,
          messages: {
            validWithin28Days: 'Both dates must be within the next 28 days.'
          }
        });
        
      }else{
        if ($('.income_pay_last input').is(':visible')){
          $('.income_pay_last').val('')
          $('.income_pay_last').hide()
          $('.income_pay_last input').prop('required', false)
        }

        $('#applicant_next_payment_date').rules('add', {
          validWithin28Days: false,
          validDateRange: false
        });

        $('#applicant_income_pay_last').rules('add', {
          validWithin28Days: false,
          validDateRange: false
        });

        nextPaymentDateLimit($(this))
      }
    });

    $('#applicant_next_payment_date').keyup(function(){
      addSlash($(this))
    })
  }
}
window.bankDetailValidator = function() {
  $.validator.addMethod("accountcardregex", function(value, element) {
    return this.optional(element) || /^[0-9]*$/.test(value);
  });
  
  $.validator.addMethod("dateregex", function(value, element) {
    return this.optional(element) || /^\d{2}\/\d{2}\/\d{4}$/.test(value);
  });

  $.validator.addMethod("validDateRange", function(value, element) {
    var payLast = $('#applicant_income_pay_last').datepicker('getDate');
    var payNext = $('#applicant_next_payment_date').datepicker('getDate');
    
    if (!payLast || !payNext) {
      return true; // Skip if no dates selected
    }

    var timeDiff = (payNext - payLast) / (1000 * 60 * 60 * 24); // Difference in days

    // Validate the date difference
    if (payNext < payLast || timeDiff < 10 || timeDiff > 16) {
      return false;
    }

    return true;
  });

  $.validator.addMethod("validWithin28Days", function(value, element) {
    var payLast = $('#applicant_income_pay_last').datepicker('getDate');
    var payNext = $('#applicant_next_payment_date').datepicker('getDate');
    
    if (!payLast || !payNext) {
      return true; // Skip if no dates selected
    }

    var today = new Date();
    var maxDate = new Date();
    maxDate.setDate(today.getDate() + 28);

    if (payLast > maxDate || payNext > maxDate) {
      return false;
    }

    return true;
  });
}

// window.applyDatePickerNextPaymentDate =  function(){
//   $('#applicant_next_payment_date').datepicker({
//     format: 'mm/dd/yyyy',
//     autoclose: true,
//     todayHighlight: true,
//     startDate: '+1D',
//     endDate: '+7D'
//   });
// }
